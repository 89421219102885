<template>
  <div class="d-flex flex-column flex-1">
    <module-navigation-bar title="Project Gantt">
      <template slot="module-nav-logo">
        <img
            v-if="isDaiwaLicense"
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
          height="40px"
          alt=""
        />
      </template>
    </module-navigation-bar>
    <div ref="ant-gantt-container" class="d-flex flex-column flex-1 pa-2">
      <div class="py-2 d-flex align-center" style="max-width: 100vw">
        <v-btn-toggle
          v-model="taskFilter"
          dense
          mandatory
          color="primary"
          rounded
        >
          <v-btn>{{ $t('modules.projectGantt.filters.allTasks') }}</v-btn>
          <v-btn>{{ $t('modules.projectGantt.filters.myTasks') }}</v-btn>
        </v-btn-toggle>
        <v-spacer />
        <v-btn-toggle
          v-model="ganttScale"
          dense
          mandatory
          color="primary"
          rounded
        >
          <v-btn v-for="option in ganttZoomLevels" :key="option.key"
            >{{ option.value }}
          </v-btn>
        </v-btn-toggle>
        <v-menu bottom left>
          <template #activator="{ on, attrs }">
            <v-icon class="ant-icon ml-2" dense v-bind="attrs" v-on="on"
              >mdi-dots-vertical</v-icon
            >
          </template>
          <v-list dense>
            <!--            <v-list-item @click="showComments = !showComments">-->
            <!--              <v-list-item-icon class="mr-2">-->
            <!--                <v-icon dense>-->
            <!--                  {{ showComments ? 'mdi-message-off' : 'mdi-message' }}-->
            <!--                </v-icon>-->
            <!--              </v-list-item-icon>-->
            <!--              <v-list-item-title>-->
            <!--                {{ showComments ? 'Hide' : 'Show' }}-->
            <!--                comments</v-list-item-title-->
            <!--              >-->
            <!--            </v-list-item>-->
            <v-list-item
              @click="
                $refs['tasks-gantt'].showCriticalPath =
                  !$refs['tasks-gantt'].showCriticalPath
              "
            >
              <v-list-item-icon class="mr-2">
                <v-icon dense> mdi-map-marker-path </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $refs['tasks-gantt']?.showCriticalPath ? 'Hide' : 'Show' }}
                critical path</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              @click="
                $refs['tasks-gantt'].treeToggle =
                  !$refs['tasks-gantt'].treeToggle
              "
            >
              <v-list-item-icon class="mr-2">
                <v-icon dense>
                  {{
                    $refs['tasks-gantt']?.treeToggle
                      ? 'mdi-triangle-down-outline'
                      : 'mdi-triangle-down'
                  }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $refs['tasks-gantt']?.treeToggle ? 'Close' : 'Open' }} all
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="$refs['tasks-gantt'].exportToMSProject()">
              <v-list-item-icon class="mr-2">
                <v-icon dense> mdi-microsoft </v-icon>
              </v-list-item-icon>
              <v-list-item-title> Export to MS project </v-list-item-title>
            </v-list-item>
            <v-list-item @click="$refs['tasks-gantt'].exportToPdf()">
              <v-list-item-icon class="mr-2">
                <v-icon dense> mdi-file-pdf-box </v-icon>
              </v-list-item-icon>
              <v-list-item-title> Export to Pdf </v-list-item-title>
            </v-list-item>
            <v-list-item @click="$refs['tasks-gantt'].exportToExcel()">
              <v-list-item-icon class="mr-2">
                <v-icon dense> mdi-file-excel-box </v-icon>
              </v-list-item-icon>
              <v-list-item-title> Export to Excel </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-progress-linear
        color="primary"
        indeterminate
        height="4px"
        :style="{ opacity: $wait.is('module.tasks-gantt.tasks.get') ? 1 : 0 }"
      ></v-progress-linear>
      <div class="flex-1 d-flex overflow-hidden" style="border-radius: 10px">
        <dhme-project-gantt-chart
          v-if="!$wait.is('module.tasks-gantt.tasks.get')"
          ref="tasks-gantt"
          :tasks="tasks"
          class="mr-5"
        />
        <v-card v-if="showComments" elevation="0" outlined>
          <v-card-title
            >Comments
            <v-spacer />
            <v-btn icon @click="fetchComments">
              <v-icon dense>mdi-reload</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-timeline dense>
              <v-timeline-item
                fill-dot
                class="white--text mb-12"
                :color="stringToConstantColor(authenticatedUser.email)"
                large
              >
                <template #icon>
                  <span>
                    {{
                      authenticatedUser.firstname.charAt(0) +
                      authenticatedUser.lastname.charAt(0)
                    }}</span
                  >
                </template>
                <v-text-field
                  v-model="commentInput"
                  hide-details
                  flat
                  label="Leave a comment..."
                  solo
                  @keydown.enter="placeComment"
                >
                  <template #append>
                    <v-btn class="mx-0" depressed @click="placeComment">
                      Post
                    </v-btn>
                  </template>
                </v-text-field>
              </v-timeline-item>
              <v-timeline-item
                v-for="item in projectComments"
                :key="item.id"
                class="mb-4"
                color="primary"
                small
              >
                <template #icon>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-avatar
                        v-bind="attrs"
                        :color="stringToConstantColor(item.email)"
                        size="30"
                        v-on="on"
                      >
                        <span
                          class="white--text text-h6"
                          style="font-size: 12px !important"
                        >
                          {{
                            item.firstname.charAt(0) + item.lastname.charAt(0)
                          }}
                        </span>
                      </v-avatar>
                    </template>
                    <span>{{ item.email }}</span>
                  </v-tooltip>
                </template>
                <v-row justify="space-between">
                  <v-col cols="7">{{ item.comment }}</v-col>
                  <v-col class="text-right" cols="5"
                    >{{ formatDate(item.timestamp) }}
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { DHME_PROJECT_GANTT } from '@/modules/modules';
import DhmeProjectGanttChart from '@/modules/daiwa-house-modular-europe/ProjectGantt/DhmeProjectGanttChart.vue';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import { stringToConstantColor } from '@/components/Charts/utils/tasks-chart.utils';
import moment from 'moment/moment';

export default {
  name: 'TasksGanttDHTMLX',
  components: { ModuleNavigationBar, DhmeProjectGanttChart },
  data() {
    return {
      ganttScale: 1,
      taskFilter: 0,
      ganttZoomLevels: [
        {
          key: 'year',
          value: this.$t('modules.projectGantt.ganttZoomLevels.yearly'),
        },
        {
          key: 'month',
          value: this.$t('modules.projectGantt.ganttZoomLevels.monthly'),
        },
        {
          key: 'week',
          value: this.$t('modules.projectGantt.ganttZoomLevels.weekly'),
        },
        {
          key: 'day',
          value: this.$t('modules.projectGantt.ganttZoomLevels.daily'),
        },
      ],
      commentInput: null,
      showComments: true,
    };
  },
  computed: {
    ...mapGetters(['project', 'selectedLicense', 'authenticatedUser']),
    ...mapGetters({
      tasks: 'tasksGantt/tasks',
      advancedTaskFilters: 'tasksGantt/advancedTaskFilters',
      projectComments: 'tasksGantt/projectComments',
      isDaiwaLicense: 'isDaiwaLicense',
    }),
  },
  watch: {
    ganttScale(value) {
      this.$refs['tasks-gantt'].setZoomLevel(this.ganttZoomLevels[value].key);
    },
    taskFilter(value) {
      switch (value) {
        case 0:
          this.fetchAllProjectTasks();
          break;
        case 1:
          this.fetchMyProjectTasks();
          break;
        default:
      }
    },
    project: {
      immediate: true,
      async handler(value) {
        if (value) {
          await this.fetchAllProjectTasks();
          await this.fetchModuleData();
          await this.fetchComments();
        }
      },
    },
    // showComments(value) {
    //   if (value) {
    //     this.fetchComments();
    //   }
    // },
  },
  created() {
    this.$store.commit('tasksGantt/tasks_add_advanced_filter', {
      selectedOption: {
        icon: 'mdi-inbox',
        text: 'project',
        value: 'project',
        operators: ['=', '!='],
      },
      selectedOperator: '=',
      selectedValues: [this.project],
      valueText: 'name',
    });
    this.$store.commit('tasksGantt/tasks_add_advanced_filter', {
      selectedOption: {
        icon: 'mdi-clipboard-check-outline',
        text: 'status',
        value: 'status',
        operators: ['=', '!='],
      },
      selectedOperator: '=',
      selectedValues: [{ title: 'open' }],
      valueText: 'title',
    });
  },
  destroyed() {
    this.$store.commit('tasksGantt/tasks_reset_advanced_filters');
  },
  methods: {
    formatDate(timestamp) {
      return moment.unix(timestamp).format('DD-MM-YYYY HH:mm');
    },
    stringToConstantColor,
    async placeComment() {
      await this.$store.dispatch('tasksGantt/placeComment', this.commentInput);
      this.commentInput = null;
    },
    fetchModuleData() {
      if (this.project.modules.find((m) => m.route === DHME_PROJECT_GANTT)) {
        this.$store.dispatch('tasksGantt/fetchModuleData', {
          projectId: this.project.id,
          moduleId: this.project.modules.find(
            (m) => m.route === DHME_PROJECT_GANTT
          ).id,
          sessionId: this.$route.params.sessionId ?? null,
        });
      }
    },
    async fetchComments() {
      await this.$store.dispatch('tasksGantt/fetchComments');
    },
    async fetchAllProjectTasks() {
      await this.$store.dispatch('tasksGantt/fetchTasks');
    },
    async fetchMyProjectTasks() {
      await this.$store.dispatch('tasksGantt/fetchTasks', [
        {
          column: 'assigned_to',
          operator: '=',
          values: [this.authenticatedUser.id],
        },
      ]);
    },
  },
};
</script>
